/**
 * Applies a swipe handler to an element.
 * @param {HTMLElement} element Element to apply the swipe handler to.
 * @param {'right' | 'left'} direction Direction to swipe.
 * @param {() => void} func Function to call on swipe.
 */
export function applySwipeHandler(element, direction, func) {
  element.addEventListener('touchstart', handleTouchStart);
  element.addEventListener('touchmove', handleTouchMove);
  element.addEventListener('touchend', () => handleTouchEnd(func));

  let touchStartX = 0;
  let touchEndX = 0;

  /**
   * Measures the starting touch position.
   * @param {TouchEvent} event Touch event.
   */
  function handleTouchStart(event) {
    touchStartX = event.touches[0].clientX;
  }

  /**
   * Measures the starting touch position.
   * @param {TouchEvent} event Touch event.
   */
  function handleTouchMove(event) {
    touchEndX = event.touches[0].clientX;
  }

  /**
   * Triggers the swipe function based on the touch start and end positions.
   */
  function handleTouchEnd() {
    if(direction === 'left' && touchEndX < touchStartX || direction === 'right' && touchEndX > touchStartX) {
      func();
    }

    touchStartX = 0;
    touchEndX = 0;
  }
}
