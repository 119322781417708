/**
 * Initializes the pagination functionality.
 */
export default function initPagination() {
  const paginationForm = getPaginationForm();
  const currentPage = document.querySelector('#page');

  const pageButtons = document.querySelectorAll('.pagination .page-button');

  if(paginationForm) {
    paginationForm.addEventListener('submit', function() {
      currentPage.value = '1';
    });
  }

  const next = document.querySelector('#next');
  const previous = document.querySelector('#previous');

  pageButtons.forEach(function(pageButton) {
    pageButton.addEventListener('click', function() {
      currentPage.value = pageButton.value;
      submitPaginationForm();
    });
  });

  if(next) {
    next.addEventListener('click', function() {
      const value = currentPage.value;
      currentPage.value = (parseInt(value) + 1).toString();
      submitPaginationForm();
    });
  }

  if(previous) {
    previous.addEventListener('click', function() {
      const value = currentPage.value;
      currentPage.value = (parseInt(value) - 1).toString();
      submitPaginationForm();
    });
  }

  /**
   * Submits the pagination form.
   */
  function submitPaginationForm() {
    const selectedInput = document.querySelector('#selected-input');
    const selectedCategory = document.querySelector('.category-button.selected');

    if(selectedInput && (selectedInput.value === undefined || selectedInput.value === null || selectedInput.value === 'Alla')) {
      if(selectedCategory) {
        selectedInput.value = selectedCategory.value;
      }
    }

    if(paginationForm) {
      paginationForm.submit();
    }
  }

  /**
   * Retrieves the pagination form.
   * @returns {HTMLFormElement|null} The pagination form or null if not found.
   */
  function getPaginationForm() {
    const forms = document.querySelectorAll('form');

    for(let i = 0; i < forms.length; i++) {
      const form = forms[i];
      const pageInput = form.querySelector('#page');

      if(pageInput !== null) {
        return form;
      }
    }

    return null;
  }
}
