/**
 * @typedef {object} ScrollInfo Information about the scroll event.
 * @property {boolean} isScrollingDown Whether the user is scrolling down.
 * @property {boolean} isBeyondThreshold Whether the user has scrolled beyond the threshold.
 */

/**
 * @param {(scrollInfo: ScrollInfo) => void} handleScroll Function to handle scroll event.
 * @param {number} threshold Threshold for more granular control over handleScroll
 * @param {HTMLElement | Window} element Element to attach the scroll event listener to.
 * @returns {() => void} Abort function for the scroll event listener.
 */
export function onScroll(handleScroll, threshold = 0, element = window){
  let lastScrollTop = 0;
  const abortSignal = new AbortController();

  element.addEventListener(
    'scroll',
    function() {
      let scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollThreshold = threshold;
      const isScrollingDown = scrollTop > lastScrollTop;
      const isBeyondThreshold = scrollTop > scrollThreshold;

      handleScroll({isScrollingDown, isBeyondThreshold});

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    },
    { signal: abortSignal.signal }
  );

  return () => abortSignal.abort();
}
