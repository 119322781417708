import { onScroll } from './utils/scroll.mjs';

const NOT_TRANSPARENT_CLASS = 'not-transparent';

/**
 * This function applies the background to the menu-container
 * if the header has the no-background class.
 * @param {Element} rootElement - The root element to search within, defaults to document.body.
 */
export default function applyBackgroundChange(rootElement = document.body) {
  const header = rootElement.querySelector('.header');
  const menuContainer = rootElement.querySelector('.menu-container');

  let removeScrollHandler;

  const updateMenuBackground = () => {
    if(header.classList.contains('no-background')) {
      menuContainer.classList.add(NOT_TRANSPARENT_CLASS);
      removeScrollHandler?.();
    } else {
      menuContainer.classList.remove(NOT_TRANSPARENT_CLASS);
      removeScrollHandler = setOnScroll(menuContainer);
    }
  };

  updateMenuBackground();

  const observer = new MutationObserver(updateMenuBackground);
  observer.observe(header, { attributes: true, attributeFilter: ['class'] });
}

/**
 * @param {Element} menuContainer Menu container
 * @returns {Function} Abort function for the scroll event listener.
 */
function setOnScroll(menuContainer) {
  return onScroll(({isBeyondThreshold}) => {
    if(isBeyondThreshold) {
      menuContainer.classList.add(NOT_TRANSPARENT_CLASS);
    } else {
      menuContainer.classList.remove(NOT_TRANSPARENT_CLASS);
    }
  }, 150);
}
