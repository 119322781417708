import { toggleOverlay } from './utils/overlay.mjs';
import { lockFocus, unlockFocus } from './utils/lock-focus.js';
import { onScroll } from './utils/scroll.mjs';

/**
 * Export the menu toggle functionality.
 */
export default function init() {
  initMenuToggle();
}

/**
 * Initializes the menu toggle functionality.
 */
function initMenuToggle() {
  /**
   * Click event handler for the open menu button.
   * Toggles the overlay for the mobile menu container.
   */
  function handleMenuButtonClick() {
    toggleOverlay(menuContainer);
    toggleOverlay(overlay);
    toggleIsActive();

    const isMenuActive = mobileMenu.classList.contains('is-active');


    if(isMenuActive) {
      lockFocus(mobileMenu);
    } else {
      unlockFocus();
    }
  }

  const openMenuButton = document.querySelector('.open-menu-button');
  const menuContainer = document.querySelector('.menu');
  const overlay = document.querySelector('.overlay');
  const mobileMenu = document.querySelector('.top-row');

  openMenuButton?.addEventListener('click', handleMenuButtonClick);
}

/**
 * Show/hide desktop menu on scroll.
 */
onScroll(({isScrollingDown, isBeyondThreshold}) => {
  if(isBeyondThreshold) {
    setMenuCollapse(isScrollingDown);
  } else {
    setMenuCollapse(false);
  }
}, 150);

/**
 * @param {boolean} collapse Whether to collapse menu
 */
function setMenuCollapse(collapse) {
  const menuContainers = document.querySelectorAll('.menu-container, .mobile-menu-container');
  menuContainers.forEach(menu => menu.classList.toggle('collapsed', collapse));
}

/**
 * Toggles the 'is-active' class on the '.open-menu-button' element.
 */
function toggleIsActive() {
  const button = document.querySelector('.open-menu-button');
  const topRow = document.querySelector('.top-row');
  if(button) {
    button.classList.toggle('is-active');
    topRow.classList.toggle('is-active');
  }
}
