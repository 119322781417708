import { lockFocus, unlockFocus } from './utils/lock-focus';
import { lockScroll, unlockScroll } from './utils/lock-scroll.mjs';
import { applySwipeHandler } from './utils/mobile-gesture.mjs';

const GALLERY_MEDIA_SELECTOR = '.caption-media-block';

/**
 * Sets up the gallery modal functionality.
 */
export default function setupGalleryModal() {
  const galleries = document.querySelectorAll('.media-gallery-block');
  galleries.forEach(gallery => {
    const modal = gallery.querySelector('.media-gallery-modal');

    initOpenModalListeners(gallery);
    initCloseModalListeners(modal);
    initSwitchImageListeners(modal);
  });
}

/**
 * Initializes the event listeners for opening the modal.
 * @param {HTMLElement} gallery - The gallery element.
 */
function initOpenModalListeners(gallery) {
  const galleryMedia = gallery.querySelectorAll('.gallery .media');
  const seeMoreButton = gallery.querySelector('.overlay-text');
  const modal = gallery.querySelector('.media-gallery-modal');

  galleryMedia.forEach(media => {
    const index = media.getAttribute('data-index');
    const correspondingModalMedia = modal.querySelector(`${GALLERY_MEDIA_SELECTOR}[data-index="${index}"]`);
    media.addEventListener('click', () => {
      openModal(modal, correspondingModalMedia);
    });

    if(seeMoreButton !== null && index === '2') {
      seeMoreButton.addEventListener('click', () => {
        openModal(modal, correspondingModalMedia);
      });
    }
  });
}

/**
 * Initializes the event listeners for switching between images in the modal.
 * @param {HTMLElement} modal - The modal element.
 */
function initSwitchImageListeners(modal) {
  const prevButton = modal.querySelector('.prev-button');
  const nextButton = modal.querySelector('.next-button');

  prevButton?.addEventListener('click', () => navigateMedia('prev', modal));
  nextButton?.addEventListener('click', () => navigateMedia('next', modal));
  applySwipeHandler(modal, 'left', () => navigateMedia('next', modal));
  applySwipeHandler(modal, 'right', () => navigateMedia('prev', modal));
}

/**
 * Initializes the event listeners for closing the modal.
 * @param {HTMLElement} modal - The modal element.
 */
function initCloseModalListeners(modal) {
  const modalCloseButton = modal.querySelector('.close-button');
  modalCloseButton.addEventListener('click', () => {
    closeModal(modal);
  });

  modal.addEventListener('click', (event) => {
    if(event.target === modal) {
      closeModal(modal);
    }
  });
}

/**
 * Opens the modal and displays the corresponding media.
 * @param {HTMLElement} modal - The modal element.
 * @param {HTMLElement} correspondingModalMedia - The corresponding media element to display.
 */
function openModal(modal, correspondingModalMedia) {
  modal.classList.add('show');
  //TODO: fix this so that it works please
  lockFocus(modal);
  lockScroll(document.body);
  correspondingModalMedia.classList.add('show');

  // Check if the corresponding modal media is the first or last and update button states
  const mediaList = Array.from(modal.querySelectorAll(GALLERY_MEDIA_SELECTOR));
  const index = mediaList.indexOf(correspondingModalMedia);
  toggleButtonState(modal, index, mediaList.length);
}

/**
 * Toggles the disabled attribute of prev and next buttons based on current index.
 * @param {HTMLElement} modal - The modal element.
 * @param {number} currentIndex - The index of the currently displayed media.
 * @param {number} mediaCount - The total count of media items.
 */
function toggleButtonState(modal, currentIndex, mediaCount) {
  const prevButton = modal.querySelector('.prev-button');
  const nextButton = modal.querySelector('.next-button');

  if(prevButton) {
    if(currentIndex === 0) {
      prevButton.setAttribute('disabled', true);
    } else {
      prevButton.removeAttribute('disabled');
    }
  }

  if(nextButton) {
    if(currentIndex === mediaCount - 1) {
      nextButton.setAttribute('disabled', true);
    } else {
      nextButton.removeAttribute('disabled');
    }
  }
}

/**
 * Closes the modal and hides all media elements.
 * @param {HTMLElement} modal - The modal element to close.
 */
function closeModal(modal) {
  modal.classList.remove('show');
  unlockFocus(modal);
  unlockScroll(document.body);
  modal.querySelectorAll(GALLERY_MEDIA_SELECTOR).forEach(media => {
    media.classList.remove('show');
  });
}

/**
 * Navigates to the previous or next media item within the modal.
 * @param {'next' | 'prev'} direction - The direction to navigate ('next' or 'prev').
 * @param {HTMLElement} modal - The modal element containing media items.
 */
function navigateMedia(direction, modal) {
  const currentMedia = modal.querySelector(`${GALLERY_MEDIA_SELECTOR}.show`);
  const currentIndex = parseInt(currentMedia.getAttribute('data-index'));
  const newIndex = direction === 'prev' ? currentIndex - 1 : currentIndex + 1;

  const mediaCount = modal.querySelectorAll(GALLERY_MEDIA_SELECTOR).length;
  const newMedia = modal.querySelector(`${GALLERY_MEDIA_SELECTOR}[data-index="${newIndex}"]`);
  if(newMedia) {
    currentMedia.classList.remove('show');
    newMedia.classList.add('show');
    toggleButtonState(modal, newIndex, mediaCount);
  }
}
