import { animate, setupEnterScreenAnimations } from './utils/animation-utils.mjs';

/**
 * Initializes all animations on the page if the user does not prefer reduced motion.
 * It sets up animations for elements that should animate when entering the screen and
 * initializes header animations.
 */
function initAnimations() {
  const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
  if(prefersReducedMotion) {
    return;
  }

  initEnterScreenAnimations();
  initHeaderAnimations();
}

/**
 * Initializes header animations by selecting the header element and applying animations to its child elements.
 * It selects the `h1` element within the header and applies a 'clip-right' animation with a delay of 100ms.
 * It also selects the `p` element within the header and applies the same 'clip-right' animation with a delay of 550ms.
 * If the header or any of its child elements are not found, the function exits without applying animations.
 */
function initEnterScreenAnimations() {
  setupEnterScreenAnimations([
    '.page-listing-block > *',
    '.mixed-listing-block > *'
  ]);
}

/**
 * Initializes animations for various blocks on the page that should animate upon entering the viewport.
 * It uses the `setupEnterScreenAnimations` utility function to set up the animations for a predefined
 * list of CSS selectors. Each selector corresponds to a block or component on the page that will have
 * the 'fade-up' animation applied as it comes into view.
 */
function initHeaderAnimations() {
  const header = document.querySelector('.header');
  if(header === null) {
    return;
  }

  const heading = header.querySelector('h1');
  const ingress = header.querySelector('p');

  animate(heading, 'clip-right', 100);
  animate(ingress, 'clip-right', 100);
}

export default initAnimations;
