/**
 * Initializes the slideshow functionality on the page.
 */
function initSlideshow() {
  const slideshowBlocks = document.querySelectorAll('.slideshow-block');

  slideshowBlocks.forEach((block) => {
    const slideshow = block.querySelector('.slideshow');
    if(!slideshow) return;

    const slides = slideshow.getElementsByClassName('slide');
    if(slides.length <= 1) return;

    const arrowLeft = slideshow.querySelector('.arrow-left');
    const arrowRight = slideshow.querySelector('.arrow-right');
    const dots = slideshow.querySelectorAll('.indicator');

    let slideIndex = 1;
    let startX = 0;
    let startY = 0;
    let isSwiping = false;

    showSlide(slideIndex);

    arrowLeft.addEventListener('click', () => {
      showSlide((slideIndex -= 1));
    });

    arrowRight.addEventListener('click', () => {
      showSlide((slideIndex += 1));
    });

    dots.forEach((dot, index) => {
      dot.addEventListener('click', () => {
        showSlide((slideIndex = index + 1));
      });
    });

    slideshow.addEventListener('touchstart', handleTouchStart);
    slideshow.addEventListener('touchmove', handleTouchMove);
    slideshow.addEventListener('touchend', handleTouchEnd);

    /**
     * Handles the start of a touch event on the slideshow.
     * @param {TouchEvent} event - The touch start event object.
     */
    function handleTouchStart(event) {
      startX = event.touches[0].clientX;
      startY = event.touches[0].clientY;
      isSwiping = true;
    }

    /**
     * Handles the movement during a touch event on the slideshow.
     * @param {TouchEvent} event - The touch move event object.
     */
    function handleTouchMove(event) {
      if(!isSwiping) return;

      const currentX = event.touches[0].clientX;
      const currentY = event.touches[0].clientY;
      const diffX = startX - currentX;
      const diffY = startY - currentY;

      if(Math.abs(diffX) > Math.abs(diffY)) {
        event.preventDefault();
      }
    }

    /**
     * Handles the end of a touch event on the slideshow.
     * @param {TouchEvent} event - The touch end event object.
     */
    function handleTouchEnd(event) {
      if(!isSwiping) return;

      const currentX = event.changedTouches[0].clientX;
      const diffX = startX - currentX;

      if(Math.abs(diffX) > 50) {
        if(diffX > 0) {
          showSlide((slideIndex += 1));
        } else {
          showSlide((slideIndex -= 1));
        }
      }

      isSwiping = false;
    }

    /**
     * Displays the slide corresponding to the index provided, wrapping around if the index is out of bounds.
     * @param {number} n - The index of the slide to show (1-based).
     */
    function showSlide(n) {
      if(n > slides.length) {
        slideIndex = 1;
      }
      if(n < 1) {
        slideIndex = slides.length;
      }
      for(let i = 0; i < slides.length; i++) {
        slides[i].style.display = 'none';
      }
      for(let i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(' current', '');
      }
      slides[slideIndex - 1].style.display = 'block';
      dots[slideIndex - 1].className += ' current';
    }
  });
}

export default initSlideshow;
