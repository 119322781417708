import '../styles/fonts.scss';
import '../styles/main.scss';
import accordionAnimation from './utils/accordion.mjs';
import initMainMenu from './main-menu';
import initAnimations from './setup-animations.mjs';
import './konamiCode';
import initSlideshow from './slideshow.js';
import setupGalleryModal from './media-gallery';
import applyClickableContainers from './clickable-containers.mjs';
import applyBackgroundChange from './menu-background.mjs';
import initPagination from './pagination.mjs';

initMainMenu();
accordionAnimation('.faq-item details', '.faq-answer');
initAnimations();
initSlideshow();
setupGalleryModal();
applyClickableContainers();
applyBackgroundChange();
initPagination();

